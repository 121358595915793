<template>
  <div class="page">
    <div class="page-row">
      <div class="content">
        <p>{{description}}</p>
      </div>
      <div class="info-list"
        v-for="(item, index) in infoList"
        :key="index"
        @click="jumpUrl(item)">
        <span>{{item.name}}</span>
        <img
          src="//download-cos.yofish.com/yofish-gongjushiyebu/20220919105218119-arrow-right.png"
          alt="">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'InformationHome',
  mounted() {
    document.title = '个人信息收集清单';
  },
  computed: {
    description() {
      const { appName } = this;
      return `${appName}将尽全力保护您的个人安全，并保障您在个人信息处理过程中的相关权益。为了向您提供${appName}的基本功能及附加功能，我们需要收集您在使用服务时通过您主动授权/提供等方式产生的个人信息。以下我们将逐一说明收集情况，方便您快速查阅。`;
    },
    appName() {
      return this.$route.query?.appName || '有鱼记账';
    },
    appType() {
      return this.$route.query?.appType || 1;
    },
    infoList() {
      return [
        {
          name: '用户基本信息',
          targetUrl: '/information/user/details',
          type: 'basic',
        },
        {
          name: '用户身份信息',
          targetUrl: '/information/user/details',
          type: 'identity',
        },
        {
          name: '账号信息',
          targetUrl: '/information/user/details',
          type: 'account',
        },
        {
          name: '位置信息',
          targetUrl: '/information/user/details',
          type: 'location',
        },
        {
          name: '设备信息',
          targetUrl: '/information/user/details',
          type: 'device',
        },
      ];
    },
  },
  methods: {
    jumpUrl(data) {
      const { type, targetUrl, name } = data;
      this.$router.push({
        path: targetUrl,
        query: {
          type,
          name,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-row {
  padding: 20px 40px;
  .content {
    font-size: 26px;
    line-height: 45px;
    font-weight: 500;
  }
  .info-list {
    padding: 15px 0;
    font-size: 26px;
    font-weight: 500;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1PX solid #e6e6e6;
    align-items: center;
    img {
      width: 50px;
    }
  }
}
</style>
